import React from 'react';
import { useTranslation } from 'react-i18next';

function MaintenancePreview({ title, content, publish_date, platforms = [], onClick }) {
  const { t } = useTranslation();

  const styles = {
    container: {
      width: '320px',
      height: '400px',
      padding: '16px',
      backgroundColor: '#f9f9f9',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      cursor: 'pointer',
      transition: 'transform 0.2s',
      position: 'relative',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: '8px',
    },
    publish_date: {
      fontSize: '12px',
      color: '#888',
      margin: '0',
    },
    platformTags: {
      display: 'flex',
      gap: '4px',
    },
    platformTag: {
      padding: '1px 8px',
      borderRadius: '16px',
      fontSize: '12px',
      backgroundColor: '#e0e0e0',
      color: '#666',
    },
    title: {
      fontSize: '24px',
      fontWeight: 'bold',
      margin: '0 0 16px 0',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      lineHeight: '1.3',
      minHeight: '62px',
    },
    contentWrapper: {
      flex: 1,
      overflow: 'hidden',
      position: 'relative',
      marginBottom: '20px',
    },
    content: {
      fontSize: '16px',
      lineHeight: '1.5',
      overflow: 'hidden',
      maxHeight: '240px',
    },
    clickGuide: {
      position: 'absolute',
      bottom: '8px',
      left: '16px',
      fontSize: '12px',
      color: '#666',
      fontStyle: 'italic',
    }
  };

  return (
    <div style={styles.container} onClick={onClick}>
      <div style={styles.header}>
        <p style={styles.publish_date}>{publish_date}</p>
        <div style={styles.platformTags}>
          {platforms.includes('ios') && (
            <span style={styles.platformTag}>iOS</span>
          )}
          {platforms.includes('android') && (
            <span style={styles.platformTag}>Android</span>
          )}
        </div>
      </div>
      <h2 style={styles.title}>{title}</h2>
      <div style={styles.contentWrapper}>
        <div 
          style={styles.content}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      <span style={styles.clickGuide}>
        {t('maintenance.preview.clickToShowAll')}
      </span>
    </div>
  );
}

export default MaintenancePreview;