import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import conotyLogo from "images/conoty-lp-top/conoty-logo-wide.png";
import appStoreBadge from "images/conoty-lp-body/Download_on_the_App_Store_Badge_JP_RGB_blk_100317.svg";
import googlePlayBadge from "images/conoty-lp-body/GetItOnGooglePlay_Badge_Web_color_Japanese.png";

const HeadingInfoContainer = styled.div`
  ${tw`flex flex-col items-center`}
  margin: 40px 0;
  padding: 0 16px;
`;

const HeadingDescription = styled.p`
  ${tw`mt-4 font-medium text-gray-600 max-w-sm`}
  text-align: left; /* 左揃えに変更 */
`;

const BadgeContainer = styled.div`
  ${tw`flex items-center mt-6`}
`;
const Badge = styled.img`
  height: 48px; /* 高さをiOS側に合わせる */
  margin: 0 16px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;
const Divider = styled.div`
  ${tw`bg-white`}
  width: 24px;
  height: 48px;
`; /* 縦線としてデザイン */
const Logo = tw.img`w-64`;

const TopMessage = () => {
  const { t } = useTranslation();

  const DownloadButtons = () => (
    <BadgeContainer>
      <a href="https://apps.apple.com/jp/app/conoty/id6504469818" target="_blank" rel="noopener noreferrer">
        <Badge src={appStoreBadge} alt={t('topMessage.downloadButtons.appStore')} />
      </a>
      <Divider />
      <a href="https://play.google.com/store/apps/details?id=com.conoty.conoty" target="_blank" rel="noopener noreferrer">
        <Badge src={googlePlayBadge} alt={t('topMessage.downloadButtons.googlePlay')} />
      </a>
    </BadgeContainer>
  );

  return (
    <HeadingInfoContainer>
      <Logo src={conotyLogo} alt="CONOTY Logo" />
      <HeadingDescription>
        {t('topMessage.shortDescription')}
      </HeadingDescription>
      <HeadingDescription>
        {t('topMessage.fullDescription')}
      </HeadingDescription>
      <DownloadButtons />
    </HeadingInfoContainer>
  );
};

export default TopMessage;
