import React from 'react';

function MaintenanceModal({ isOpen, onClose, title, content, createdAt, platforms = [] }) {
  if (!isOpen) return null;

  const styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    },
    modal: {
      backgroundColor: 'white',
      padding: '24px',
      borderRadius: '12px',
      width: '90%',
      maxWidth: '600px',
      maxHeight: '80vh',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column'
    },
    contentContainer: {
      overflow: 'auto',
      flex: 1,
      marginRight: '-8px',  // スクロールバーのスペースを確保
      paddingRight: '8px'   // 内容が右端まで表示されるように調整
    },
    headerSection: {
      flex: 'none'  // ヘッダー部分は固定
    },
    closeButton: {
      position: 'absolute',
      top: '16px',
      right: '16px',
      border: 'none',
      background: 'none',
      fontSize: '24px',
      cursor: 'pointer'
    },
    title: {
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '16px'
    },
    metadataContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight: '24px' // ×ボタンのスペースを確保
    },
    createdAt: {
      fontSize: '14px',
      color: '#888'
    },
    platformTags: {
      display: 'flex',
      gap: '8px'
    },
    platformTag: {
      padding: '1px 12px',
      borderRadius: '16px',
      fontSize: '14px',
      backgroundColor: '#e0e0e0'
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div style={styles.overlay} onClick={handleOverlayClick}>
      <div style={styles.modal}>
        <button style={styles.closeButton} onClick={onClose}>&times;</button>
        <div style={styles.headerSection}>
          <div style={styles.metadataContainer}>
            <p style={styles.createdAt}>{createdAt}</p>
            <div style={styles.platformTags}>
              {platforms.includes('ios') && (
                <span style={styles.platformTag}>iOS</span>
              )}
              {platforms.includes('android') && (
                <span style={styles.platformTag}>Android</span>
              )}
            </div>
          </div>
          <h2 style={styles.title}>{title}</h2>
        </div>
        <div style={styles.contentContainer}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </div>
  );
}

export default MaintenanceModal;