import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.div`max-w-4xl mx-auto px-4 py-8`;

const CategorySection = styled.section`
  ${tw`mb-12`}
`;

const CategoryHeader = styled.div`
  ${tw`flex flex-col md:flex-row items-start mb-6`}
`;

const CategoryTitleWrapper = styled.div`
  ${tw`w-full md:w-1/3 mb-4 md:mb-0`}
`;

const CategoryTitle = styled.h2`
  ${tw`text-xl font-bold`}
  color: #ff904f;
`;

const CategoryMessage = tw.p`text-sm text-gray-600 mt-1`;

const ContentWrapper = styled.div`
  ${tw`w-full md:w-2/3`}
`;

const ContentList = tw.ul`space-y-3`;

const ContentItem = styled.li`
  ${tw`p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200`}
  cursor: pointer;
`;

const ContentTitle = tw.h3`text-lg font-medium text-gray-800`;

const ContentBody = styled.div`
  ${tw`mt-4 text-gray-600 text-sm leading-relaxed`}
  white-space: pre-line;
`;

const AccordionContent = styled.div`
  ${tw`overflow-hidden transition-all duration-300 ease-in-out`}
  max-height: ${props => props.isOpen ? '1000px' : '0'};
  opacity: ${props => props.isOpen ? '1' : '0'};
`;

const categoryOrder = [
  'introApp',
  'startApp',
  'conotyBasic',
  'groupSet',
  'accountSet',
  'premiumIntro',
  'feedback',
  'accountDelete'
];


const specialCategories = {
  'startApp': ['groupCreation', 'memberInvitation', 'receiveNotifications'],
  'conotyBasic': [
    'noticeList',
    'comments',
    'readStatus',
    'newArrival',
    'starredNotices',
    'myTodo',
    'attachedFiles',
    'calendar'
  ]
};

export default function GuideList() {
  const { t } = useTranslation();
  const [openItems, setOpenItems] = useState({});

  const toggleItem = (categoryId, contentId) => {
    setOpenItems(prev => ({
      ...prev,
      [`${categoryId}-${contentId}`]: !prev[`${categoryId}-${contentId}`]
    }));
  };

  const getContentsByCategory = (category) => {
    if (category in specialCategories) {
      return specialCategories[category];
    }
    return [category];
  };

  return (
    <Container>
      {categoryOrder.map((category) => (
        <CategorySection key={category}>
          <CategoryHeader>
            <CategoryTitleWrapper>
              <CategoryTitle>
                {t(`categories.${category}.title`)}
              </CategoryTitle>
              <CategoryMessage>
                {t(`categories.${category}.message`)}
              </CategoryMessage>
            </CategoryTitleWrapper>
            
            <ContentWrapper>
              <ContentList>
                {getContentsByCategory(category).map((content) => (
                  <ContentItem 
                    key={content}
                    onClick={() => toggleItem(category, content)}
                  >
                    <ContentTitle>
                      {category in specialCategories 
                        ? t(`${category}.${content}.title`)
                        : t(`${category}.title`)}
                    </ContentTitle>
                    <AccordionContent isOpen={openItems[`${category}-${content}`]}>
                      <ContentBody>
                        {category in specialCategories 
                          ? t(`${category}.${content}.content`)
                          : t(`${category}.content`)}
                      </ContentBody>
                    </AccordionContent>
                  </ContentItem>
                ))}
              </ContentList>
            </ContentWrapper>
          </CategoryHeader>
        </CategorySection>
      ))}
    </Container>
  );
}