import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { collection, query, getDocs, orderBy, limit, startAfter, getCountFromServer } from 'firebase/firestore';
import { db } from '../../firebase';
import MaintenancePreview from './maintenance-preview';
import MaintenanceModal from './maintenance-modal';

const ITEMS_PER_PAGE = 9;

const UpdateNotesContents = () => {
  const { i18n } = useTranslation();
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [columns, setColumns] = useState(3);
  const [updateNotes, setUpdateNotes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchTotalCount = useCallback(async () => {
    try {
      const coll = collection(db, 'publish_update_notes');
      const snapshot = await getCountFromServer(coll);
      const total = snapshot.data().count;
      console.log('総件数:', total); // デバッグログ
      setTotalPages(Math.ceil(total / ITEMS_PER_PAGE));
    } catch (error) {
      console.error('総件数の取得中にエラーが発生しました:', error);
    }
  }, []);
  
  const processQuerySnapshot = useCallback((querySnapshot) => {
    const notes = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      const platforms = [];
      if (data.devices) {
        if (data.devices.iOS) platforms.push('ios');
        if (data.devices.Android) platforms.push('android');
        if (data.devices.WEB) platforms.push('web');
      }
  
      const title = i18n.language === 'ja' ? data.title_ja : data.title_en;
      const content = i18n.language === 'ja' ? data.content_ja : data.content_en;
  
      return {
        id: doc.id,
        title: title,
        content: content,
        publish_date: data.publish_date.toDate().toISOString().split('T')[0],
        platforms: platforms,
      };
    });
    setUpdateNotes(notes);
  }, [i18n.language]);
  
  const fetchUpdateNotes = useCallback(
    async (page) => {
      try {
        const q = query(
          collection(db, 'publish_update_notes'),
          orderBy('publish_date', 'desc'),
          limit(ITEMS_PER_PAGE)
        );
  
        if (page > 1) {
          const prevPageDocs = await getDocs(
            query(
              collection(db, 'publish_update_notes'),
              orderBy('publish_date', 'desc'),
              limit((page - 1) * ITEMS_PER_PAGE)
            )
          );
          const lastVisible = prevPageDocs.docs[prevPageDocs.docs.length - 1];
          const nextQuery = query(
            collection(db, 'publish_update_notes'),
            orderBy('publish_date', 'desc'),
            startAfter(lastVisible),
            limit(ITEMS_PER_PAGE)
          );
          const querySnapshot = await getDocs(nextQuery);
          processQuerySnapshot(querySnapshot);
        } else {
          const querySnapshot = await getDocs(q);
          processQuerySnapshot(querySnapshot);
        }
      } catch (error) {
        console.error('アップデートノートの取得中にエラーが発生しました:', error);
      }
    },
    [processQuerySnapshot]
  );
  
  useEffect(() => {
    fetchTotalCount();
    fetchUpdateNotes(1);
  }, [fetchTotalCount, fetchUpdateNotes]);

  useEffect(() => {
    const checkScreenSize = () => {
      if (window.matchMedia('(max-width: 768px)').matches) {
        setColumns(1);
      } else if (window.matchMedia('(max-width: 1024px)').matches) {
        setColumns(2);
      } else {
        setColumns(3);
      }
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchUpdateNotes(page);
  };

  const containerStyle = {
    display: 'grid',
    gap: '24px',
    padding: '24px',
    margin: '0 auto',
    justifyContent: 'center',
    gridTemplateColumns: `repeat(${columns}, 320px)`,
  };

  const paginationStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    padding: '24px',
  };

  const pageButtonStyle = (isActive) => ({
    padding: '8px 12px',
    borderRadius: '4px',
    backgroundColor: isActive ? '#ff904fff' : 'white',
    color: isActive ? 'white' : '#333',
    cursor: 'pointer',
  });

  const renderPaginationButtons = () => {
    // 総ページ数が0以下の場合は何も表示しない
    if (totalPages < 1) return null;
  
    const buttons = [];
    // 最初のページ
    buttons.push(
      <button
        key={1}
        style={pageButtonStyle(currentPage === 1)}
        onClick={() => handlePageChange(1)}
      >
        1
      </button>
    );
  
    // 2ページ以上ある場合の処理
    if (totalPages > 1) {
      if (currentPage > 3) {
        buttons.push(<span key="ellipsis1">...</span>);
      }
  
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(currentPage + 1, totalPages - 1); i++) {
        buttons.push(
          <button
            key={i}
            style={pageButtonStyle(currentPage === i)}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
  
      if (currentPage < totalPages - 2) {
        buttons.push(<span key="ellipsis2">...</span>);
      }
  
      // 最終ページ
      buttons.push(
        <button
          key={totalPages}
          style={pageButtonStyle(currentPage === totalPages)}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </button>
      );
    }
  
    return buttons;
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  return (
    <>
      <div style={containerStyle}>
        {updateNotes.map((item) => (
          <MaintenancePreview
            key={item.id}
            title={item.title}
            content={item.content}
            publish_date={item.publish_date}
            platforms={item.platforms}
            onClick={() => handleItemClick(item)}
          />
        ))}
      </div>

      {/* ページ総数が 1 以上ならページネーションを表示する */}
      {totalPages >= 1 && (
        <div style={paginationStyle}>
          {renderPaginationButtons()}
        </div>
      )}

      <MaintenanceModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title={selectedItem?.title}
        content={selectedItem?.content}
        publish_date={selectedItem?.publish_date}
        platforms={selectedItem?.platforms}
      />
    </>
  );
};

export default UpdateNotesContents;
